import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import notify from 'notify';
import Button from 'components/Button';
import Input from 'components/Input';
import { resetPassword } from './api';
import { StyledResetPasswordPage } from './styles';

const ResetPasswordPage = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const submitForm = async values => {
    try {
      await resetPassword(values.email);
      setShowSuccessMessage(true);
      // navigate('/');
    } catch (err) {
      notify(err.message);
    }
  };

  if (showSuccessMessage) {
    return (
      <StyledResetPasswordPage className="success-message">
        <h1 className="title">Email is sent</h1>
        <div className="info">
          Check your inbox for a password reset email. Click on
          <br /> the URL provided in the email to set a new password.
        </div>
      </StyledResetPasswordPage>
    );
  }

  return (
    <StyledResetPasswordPage>
      <h1 className="title">Reset password</h1>
      <div className="info">
        Submit your email address below and we will send a link
        <br /> shortly to reset your password.
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
          name="email"
          label="Email"
          placeholder="Enter Email"
          error={errors.email?.message}
        />
        <Button className="button-submit">Send</Button>
      </form>
    </StyledResetPasswordPage>
  );
};

export default ResetPasswordPage;
