import { styled } from 'styles';

export const StyledResetPasswordPage = styled.div`
  /* mobile first */
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &.success-message .title {
    margin-bottom: 66px;
  }

  .info {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 24px;

    br {
      display: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .input,
    .button {
      width: 100%;
    }

    .input {
      margin-bottom: 20px;
    }

    .button-submit {
      display: block;
      width: 100%;
      height: 40px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-height: 890px;

    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      margin: 0 0 30px 0;
    }

    .info {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 36px;

      br {
        display: initial;
      }
    }

    form {
      display: initial;

      .input {
        width: 300px;
        margin-right: 20px;
      }

      .button-submit {
        height: 40px;
        width: 300px;
      }
    }
  }
`;
